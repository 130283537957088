<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-12">
						<a-tabs @change="onChangeTab" :activeKey="activeTab" :defaultActiveKey="activeTab">
							<a-tab-pane v-for="element in actualSubmenus" :tab="element.title" :key="element.key" force-render>
								<div v-if="element.key == 'dashboard_dashboard'">
									<directionDashboard />
								</div>
							</a-tab-pane>
						</a-tabs>
					</div>
				</div>
			</div>
		</div>
	</a-spin>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import utilities from '@/services/utilities'
import directionDashboard from '@/components/direction/dashboard'

export default {
	name: 'DireccionView',
	components: {
		directionDashboard,
	},
	computed: {
		...mapGetters('direction', ['spinnerLoaderLabel', 'spinnerLoader']),
		...mapState(['settings']),
		actualSubmenus() {
			if (utilities.objectValidate(this.settings, 'menu', false)) {
				let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
				this.onChangeTab(this.settings.menu[index].submenus[0].key)
				if (utilities.objectValidate(this.settings, ['menu', index, 'submenus'], false)) {
					return this.settings.menu[index].submenus
				}
				return []
			} else {
				return []
			}
		},
	},
	mounted() { },
	data() {
		return {
			activeTab: '',
		}
	},
	methods: {
		onChangeTab(key) {
			this.activeTab = key
		},
	},
}
</script>